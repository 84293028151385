import loadable from "@loadable/component";
import React from "react";
import { DashboardLoadingAndErrorWrapper } from "metabase/dashboard/components/Dashboard/Dashboard.styled";

const options = {
  fallback: (
    <DashboardLoadingAndErrorWrapper
      loading={true}
      isFullHeight={true}
      isFullscreen=""
      isNightMode={false}
    />
  ),
};

// prettier-ignore
export default {
  App: loadable(() => import("metabase/App.tsx")),
  Question: loadable(
    () => import("metabase/query_builder/containers/Question"),
    options,
  ),
  NotFoundFallbackPage: loadable(() =>
    import("metabase/containers/NotFoundFallbackPage"),
  ),
  SetupApp: loadable(() => import("metabase/setup/containers/SetupApp")),
  PublicQuestion: loadable(() =>
    import("metabase/public/containers/PublicQuestion"),
  ),
  PublicDashboard: loadable(() =>
    import("metabase/public/containers/PublicDashboard"),
  ),
  PublicNotFound: loadable(() =>
    import("metabase/public/components/PublicNotFound"),
  ),
  PublicApp: loadable(() => import("metabase/public/containers/PublicApp")),
  ArchiveDashboardModal: loadable(() =>
    import("metabase/dashboard/containers/ArchiveDashboardModal"),
  ),
  // DashboardHistoryModal: loadable(() => import("metabase/dashboard/components/DashboardHistoryModal")),
  DashboardMoveModal: loadable(() =>
    import("metabase/dashboard/components/DashboardMoveModal"),
  ),
  DashboardCopyModal: loadable(() =>
    import("metabase/dashboard/components/DashboardCopyModal"),
  ),
  DashboardDetailsModal: loadable(() =>
    import("metabase/dashboard/components/DashboardDetailsModal"),
  ),
  ArchiveApp: loadable(() => import("metabase/home/containers/ArchiveApp")),
  SearchApp: loadable(() => import("metabase/home/containers/SearchApp")),
  CustomUpload: loadable(() => import("metabase/containers/customUpload")),
  Creator: loadable(() => import("metabase/containers/creator"), options),
  About: loadable(() => import("metabase/containers/aboutV2")),

  // account
  AccountApp: loadable(() => import("./account/app/containers/AccountApp")),

  // account
  UserProfileApp: loadable(() =>
    import("./account/profile/containers/UserProfileApp"),
  ),
  UserPasswordApp: loadable(() =>
    import("./account/password/containers/UserPasswordApp"),
  ),
  DeveloperApp: loadable(() =>
    import("./account/developer/containers/DeveloperApp"),
  ),

  /* Browse data */
  PulseEditApp: loadable(() =>
    import("metabase/pulse/containers/PulseEditApp"),
  ),
  NewModelOptions: loadable(() =>
    import("metabase/new_model/containers/NewModelOptions"),
  ),
  CreateDashboardModal: loadable(() =>
    import("metabase/components/CreateDashboardModal"),
  ),
  UserCollectionList: loadable(() =>
    import("metabase/containers/UserCollectionList"),
  ),
  CollectionPermissionsModal: loadable(() =>
    import(
      "metabase/admin/permissions/components/CollectionPermissionsModal/CollectionPermissionsModal"
      ),
  ),
  ArchiveCollectionModal: loadable(() =>
    import("metabase/components/ArchiveCollectionModal"),
  ),
  MoveCollectionModal: loadable(() =>
    import("metabase/collections/containers/MoveCollectionModal"),
  ),
  CollectionCreate: loadable(() =>
    import("metabase/collections/containers/CollectionCreate"),
  ),
  QueryBuilder: loadable(() =>
    import("metabase/query_builder/containers/QueryBuilder"),
  ),
  TableBrowser: loadable(() =>
    import("metabase/browse/containers/TableBrowser"),
  ),
  SchemaBrowser: loadable(() =>
    import("metabase/browse/containers/SchemaBrowser"),
  ),
  DatabaseBrowser: loadable(() =>
    import("metabase/browse/containers/DatabaseBrowser"),
  ),
  BrowseApp: loadable(() => import("metabase/browse/components/BrowseApp")),

  /* Dashboards */
  DashboardApp: loadable(
    () => import("metabase/dashboard/containers/DashboardApp"),
    options,
  ),
  AutomaticDashboardApp: loadable(() =>
    import("metabase/dashboard/containers/AutomaticDashboardApp"),
  ),

  // auth containers
  ForgotPasswordApp: loadable(() =>
    import("metabase/auth/containers/ForgotPasswordApp"),
  ),
  LoginApp: loadable(() => import("metabase/auth/containers/LoginApp")),
  LogoutApp: loadable(() => import("metabase/auth/containers/LogoutApp")),
  ResetPasswordApp: loadable(() =>
    import("metabase/auth/containers/ResetPasswordApp"),
  ),
  ActivityApp: loadable(() => import("metabase/home/containers/ActivityApp")),

  // Reference Databases
  DatabaseListContainer: loadable(() =>
    import("metabase/reference/databases/DatabaseListContainer"),
  ),
  DatabaseDetailContainer: loadable(() =>
    import("metabase/reference/databases/DatabaseDetailContainer"),
  ),
  TableListContainer: loadable(() =>
    import("metabase/reference/databases/TableListContainer"),
  ),
  TableDetailContainer: loadable(() =>
    import("metabase/reference/databases/TableDetailContainer"),
  ),
  TableQuestionsContainer: loadable(() =>
    import("metabase/reference/databases/TableQuestionsContainer"),
  ),
  FieldListContainer: loadable(() =>
    import("metabase/reference/databases/FieldListContainer"),
  ),
  FieldDetailContainer: loadable(() =>
    import("metabase/reference/databases/FieldDetailContainer"),
  ),

  HomePage: loadable(() =>
    import("metabase/home/homepage/containers/HomePage"),
  ),
  CollectionLanding: loadable(() =>
    import("metabase/collections/components/CollectionLanding"),
  ),

  // Reference Segments
  SegmentListContainer: loadable(() =>
    import("metabase/reference/segments/SegmentListContainer"),
  ),
  SegmentDetailContainer: loadable(() =>
    import("metabase/reference/segments/SegmentDetailContainer"),
  ),
  SegmentQuestionsContainer: loadable(() =>
    import("metabase/reference/segments/SegmentQuestionsContainer"),
  ),
  SegmentRevisionsContainer: loadable(() =>
    import("metabase/reference/segments/SegmentRevisionsContainer"),
  ),
  SegmentFieldListContainer: loadable(() =>
    import("metabase/reference/segments/SegmentFieldListContainer"),
  ),
  SegmentFieldDetailContainer: loadable(() =>
    import("metabase/reference/segments/SegmentFieldDetailContainer"),
  ),

  // Reference Metrics
  MetricListContainer: loadable(() =>
    import("metabase/reference/metrics/MetricListContainer"),
  ),
  MetricDetailContainer: loadable(() =>
    import("metabase/reference/metrics/MetricDetailContainer"),
  ),
  MetricQuestionsContainer: loadable(() =>
    import("metabase/reference/metrics/MetricQuestionsContainer"),
  ),
  MetricRevisionsContainer: loadable(() =>
    import("metabase/reference/metrics/MetricRevisionsContainer"),
  ),

  // Tools
  Tools: loadable(() => import("metabase/admin/tools/containers/Tools")),
  RedirectToAllowedSettings: loadable(() =>
    import("metabase/admin/settings/containers/RedirectToAllowedSettings"),
  ),

  // People
  PeopleListingApp: loadable(() =>
    import("metabase/admin/people/containers/PeopleListingApp"),
  ),
  GroupsListingApp: loadable(() =>
    import("metabase/admin/people/containers/GroupsListingApp"),
  ),
  GroupDetailApp: loadable(() =>
    import("metabase/admin/people/containers/GroupDetailApp"),
  ),
  // GrowthAnalytics
  WrapDashboard: loadable(
    () => import("metabase/ab/containers/WrapDashboard"),
    options,
  ),
  GaProjectContainer: loadable(() =>
    import("metabase/ab/containers/Project"),
  ),
  ABProjectContainer: loadable(() =>
    import("metabase/ab/containers/Project"),
  ),
  GameList: loadable(() =>
    import("metabase/ab/containers/gameList"),
  ),
  UserList: loadable(() =>
    import("metabase/ab/containers/userList"),
  ),
  // Metadata / Data model
  DataModelApp: loadable(() =>
    import("metabase/admin/datamodel/containers/DataModelApp"),
  ),
  MetadataEditorApp: loadable(() =>
    import("metabase/admin/datamodel/containers/MetadataEditorApp"),
  ),
  MetricListApp: loadable(() =>
    import("metabase/admin/datamodel/containers/MetricListApp"),
  ),
  MetricApp: loadable(() =>
    import("metabase/admin/datamodel/containers/MetricApp"),
  ),
  SegmentListApp: loadable(() =>
    import("metabase/admin/datamodel/containers/SegmentListApp"),
  ),
  SegmentApp: loadable(() =>
    import("metabase/admin/datamodel/containers/SegmentApp"),
  ),
  RevisionHistoryApp: loadable(() =>
    import("metabase/admin/datamodel/containers/RevisionHistoryApp"),
  ),
  AdminPeopleApp: loadable(() =>
    import("metabase/admin/people/containers/AdminPeopleApp"),
  ),
  FieldApp: loadable(() =>
    import("metabase/admin/datamodel/containers/FieldApp"),
  ),
  TableSettingsApp: loadable(() =>
    import("metabase/admin/datamodel/containers/TableSettingsApp"),
  ),
  TroubleshootingApp: loadable(() =>
    import("metabase/admin/tasks/containers/TroubleshootingApp"),
  ),

  TasksApp: loadable(() => import("metabase/admin/tasks/containers/TasksApp")),
  TaskModal: loadable(() =>
    import("metabase/admin/tasks/containers/TaskModal"),
  ),
  JobInfoApp: loadable(() =>
    import("metabase/admin/tasks/containers/JobInfoApp"),
  ),
  JobTriggersModal: loadable(() =>
    import("metabase/admin/tasks/containers/JobTriggersModal"),
  ),
  Logs: loadable(() => import("metabase/admin/tasks/containers/Logs")),
  Help: loadable(() => import("metabase/admin/tasks/containers/Help")),
  ModelCacheRefreshJobs: loadable(() =>
    import(
      "metabase/admin/tasks/containers/ModelCacheRefreshJobs/ModelCacheRefreshJobs"
      ),
  ),
  ModelCacheRefreshJobModal: loadable(() =>
    import(
      "metabase/admin/tasks/containers/ModelCacheRefreshJobs/ModelCacheRefreshJobModal"
      ),
  ),

  //  DB Add / list
  DatabaseListApp: loadable(() =>
    import("metabase/admin/databases/containers/DatabaseListApp"),
  ),
  DatabaseEditApp: loadable(() =>
    import("metabase/admin/databases/containers/DatabaseEditApp"),
  ),

  // Settings
  SettingsEditorApp: loadable(() =>
    import("metabase/admin/settings/containers/SettingsEditorApp"),
  ),
  PremiumEmbeddingLicensePage: loadable(() =>
    import("metabase/admin/settings/containers/PremiumEmbeddingLicensePage"),
  ),

  AdminApp: loadable(() => import("metabase/admin/app/components/AdminApp")),
  NewUserModal: loadable(() =>
    import("metabase/admin/people/containers/NewUserModal"),
  ),
  UserSuccessModal: loadable(() =>
    import("metabase/admin/people/containers/UserSuccessModal"),
  ),
  UserPasswordResetModal: loadable(() =>
    import("metabase/admin/people/containers/UserPasswordResetModal"),
  ),
  EditUserModal: loadable(() =>
    import("metabase/admin/people/containers/EditUserModal"),
  ),
  UserActivationModal: loadable(() =>
    import("metabase/admin/people/containers/UserActivationModal"),
  ),

  // permissions/routes
  CollectionPermissionsPage: loadable(() =>
    import(
      "metabase/admin/permissions/pages/CollectionPermissionsPage/CollectionPermissionsPage"
      ),
  ),
  DatabasesPermissionsPage: loadable(() =>
    import(
      "metabase/admin/permissions/pages/DatabasePermissionsPage/DatabasesPermissionsPage"
      ),
  ),
  GroupsPermissionsPage: loadable(() =>
    import(
      "metabase/admin/permissions/pages/GroupDataPermissionsPage/GroupsPermissionsPage"
      ),
  ),
  DataPermissionsPage: loadable(() =>
    import("metabase/admin/permissions/pages/DataPermissionsPage"),
  ),

  //notifications/routes
  NotificationsApp: loadable(() =>
    import("metabase/account/notifications/containers/NotificationsApp"),
  ),
  HelpModal: loadable(() =>
    import("metabase/account/notifications/components/HelpModal"),
  ),
  ArchiveAlertModal: loadable(() =>
    import("metabase/account/notifications/containers/ArchiveAlertModal"),
  ),
  ArchivePulseModal: loadable(() =>
    import("metabase/account/notifications/containers/ArchivePulseModal"),
  ),
  UnsubscribeAlertModal: loadable(() =>
    import("metabase/account/notifications/containers/UnsubscribeAlertModal"),
  ),
  UnsubscribePulseModal: loadable(() =>
    import("metabase/account/notifications/containers/UnsubscribePulseModal"),
  ),

  // timelines/routes
  DeleteEventModal: loadable(() =>
    import("metabase/timelines/collections/containers/DeleteEventModal"),
  ),
  DeleteTimelineModal: loadable(() =>
    import("metabase/timelines/collections/containers/DeleteTimelineModal"),
  ),
  EditEventModal: loadable(() =>
    import("metabase/timelines/collections/containers/EditEventModal"),
  ),
  EditTimelineModal: loadable(() =>
    import("metabase/timelines/collections/containers/EditTimelineModal"),
  ),
  MoveEventModal: loadable(() =>
    import("metabase/timelines/collections/containers/MoveEventModal"),
  ),
  MoveTimelineModal: loadable(() =>
    import("metabase/timelines/collections/containers/MoveTimelineModal"),
  ),
  NewEventModal: loadable(() =>
    import("metabase/timelines/collections/containers/NewEventModal"),
  ),
  NewEventWithTimelineModal: loadable(() =>
    import(
      "metabase/timelines/collections/containers/NewEventWithTimelineModal"
      ),
  ),
  NewTimelineModal: loadable(() =>
    import("metabase/timelines/collections/containers/NewTimelineModal"),
  ),
  TimelineArchiveModal: loadable(() =>
    import("metabase/timelines/collections/containers/TimelineArchiveModal"),
  ),
  TimelineDetailsModal: loadable(() =>
    import("metabase/timelines/collections/containers/TimelineDetailsModal"),
  ),
  TimelineIndexModal: loadable(() =>
    import("metabase/timelines/collections/containers/TimelineIndexModal"),
  ),
  TimelineListArchiveModal: loadable(() =>
    import(
      "metabase/timelines/collections/containers/TimelineListArchiveModal"
      ),
  ),

  // audit_app/routes
  UnsubscribeUserModal: loadable(() =>
    import(
      "metabase-enterprise/audit_app/containers/UnsubscribeUserModal/UnsubscribeUserModal"
      ),
  ),
  AuditApp: loadable(() =>
    import("metabase-enterprise/audit_app/containers/AuditApp"),
  ),
  AuditOverview: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditOverview"),
  ),
  AuditDatabases: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditDatabases"),
  ),
  AuditDatabaseDetail: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditDatabaseDetail"),
  ),
  AuditSchemas: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditSchemas"),
  ),
  AuditSchemaDetail: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditSchemaDetail"),
  ),
  AuditTables: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditTables"),
  ),
  AuditTableDetail: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditTableDetail"),
  ),
  AuditQuestions: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditQuestions"),
  ),
  AuditQuestionDetail: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditQuestionDetail"),
  ),
  AuditDashboards: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditDashboards"),
  ),
  AuditDashboardDetail: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditDashboardDetail"),
  ),
  AuditQueryDetail: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditQueryDetail"),
  ),
  AuditUsers: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditUsers"),
  ),
  AuditUserDetail: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditUserDetail"),
  ),
  AuditDownloads: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditDownloads"),
  ),
  AuditSubscriptions: loadable(() =>
    import("metabase-enterprise/audit_app/pages/AuditSubscriptions"),
  ),
};
